var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "a",
        {
          class: _vm.showTeacherAnalysis() ? "" : "lnk-disabled",
          attrs: {
            id: "lnk-consultant-feedback",
          },
          on: {
            click: function ($event) {
              return _vm.onRedirectAnalysis(
                _vm.classroomData.class_applications
              )
            },
          },
        },
        [
          _c("font-awesome-icon", {
            attrs: {
              icon: "chart-bar",
            },
          }),
          _vm._v(" " + _vm._s(_vm.$t("evaluation.consultant_feedback"))),
        ],
        1
      ),
      _vm.type === "evaluations"
        ? _c(
            "b-tooltip",
            {
              attrs: {
                target: "lnk-consultant-feedback",
                placement: "leftbottom",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-center",
                },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("evaluation.overall_level"))),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary rounded",
                      attrs: {
                        type: "button",
                      },
                    },
                    [
                      _vm._v("Level"),
                      _vm.classroomData.class_room.analysis
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "badge badge-light rounded",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.classroomData.class_room.analysis
                                      .average_level
                                  )
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "blockquote mt-3",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("evaluation.vip_analysis_suggestion"))
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }